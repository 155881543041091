<template>
  <div class="chat-input">
    <form @submit.prevent="sendMessage">
      <div class="input-wrapper">
        <div class="input-group mb-3">
          <textarea
            class="form-control"
            v-model="message"
            :placeholder="staticText.inputPlaceholder"
            :disabled="isDisabled"
            @keydown.enter="handleEnter"
            ref="textarea"
            @input="adjustHeight"
          ></textarea>
        </div>
        <div class="button-group">
          <speech-to-text
            :disabled="isDisabled"
            @transcript="handleTranscript"
          />
          <button
            class="btn send-button"
            type="submit"
            :disabled="!message.trim() || isDisabled"
          >
            <img
              :src="sendButtonIcon"
              :alt="staticText.sendButtonLabel"
            />
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import SpeechToText from "@/components/SpeechToText"

export default {
  name: "ChatInput",
  components: {
    SpeechToText
  },
  props: {
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      message: "",
      staticTextDefault: {
        inputPlaceholder: "Type here...",
        sendButtonLabel: "Send"
      }
    }
  },
  computed: {
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    },
    sendButtonIcon () {
      return this.message.trim() && !this.isDisabled
        ? "/img/icons/send-message-orange.svg"
        : "/img/icons/send-message-grey.svg"
    }
  },
  methods: {
    handleEnter (e) {
      if (e.shiftKey) {
        // Allow shift+enter for new line
        return
      }
      e.preventDefault() // Prevent default enter behavior
      this.sendMessage()
    },
    sendMessage () {
      const trimmedMessage = this.message.trim()
      if (trimmedMessage && !this.isDisabled) {
        this.$emit("send", trimmedMessage)
        this.message = ""
        this.$nextTick(() => {
          this.adjustHeight()
        })
      }
    },
    adjustHeight () {
      const textarea = this.$refs.textarea
      if (textarea) {
        textarea.style.height = "auto"
        textarea.style.height = `${textarea.scrollHeight}px`
      }
    },
    handleTranscript (transcript) {
      this.message = this.message ? `${this.message} ${transcript}` : transcript
      this.$nextTick(() => {
        this.adjustHeight()
      })
    }
  },
  mounted () {
    this.adjustHeight()
  }
}
</script>

<style scoped lang="scss">
.chat-input {
  padding: 16px 20px;
  background: white;
  border-top: 1px solid $gray-200;

  .input-wrapper {
    position: relative;
    padding-bottom: 44px;
  }

  .input-group {
    background: white;
    border: 1px solid $gray-200;
    border-radius: 8px;
    overflow: hidden;

    .form-control {
      border: none;
      padding: 12px 16px;
      font-size: 14px;
      color: $slate80;
      min-height: 44px;
      max-height: 120px;
      height: auto;
      overflow-y: auto;
      resize: none;
      text-align: left;
      line-height: 1.5;
      vertical-align: top;

      &::placeholder {
        color: $slate40;
        text-align: left;
      }

      &:focus {
        box-shadow: none;
      }
    }
  }

  .button-group {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    gap: 8px;

    :deep(.speech-to-text) {
      .mic-button {
        padding: 8px;
        background: rgba($slate80,0.1);
        border: none;
        border-radius: 12px;
        width: 46px;
        height: 46px;
        display: flex;
        align-items: center;
        justify-content: center;

        img, svg {
          width: 20px;
          height: 20px;
        }

        &:hover:not(:disabled) {
          background: rgba($orange, 0.1);
        }

        &:disabled {
          cursor: not-allowed;
          background: rgba($slate80,0.1);
        }

        &.active {
          background-color: $orange !important;
          animation: 1s linear infinite pulsating;

          svg path {
            stroke: white !important;
            &:first-child {
              fill: white !important;
            }
          }
        }

        @keyframes pulsating {
          50% {
            transform: scale(1.1);
          }
        }
      }
    }
  }

  .send-button {
    padding: 8px;
    background: rgba($slate80,0.1);
    border: none;
    border-radius: 12px;
    width: 46px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 20px;
      height: 20px;
    }

    &:hover:not(:disabled) {
      background: rgba($orange, 0.1);
    }

    &:disabled {
      cursor: not-allowed;
      background: rgba($slate80,0.1);
    }
  }
}
</style>
