<template>
  <div class="container-fluid py-3">
    <!-- TTYD Single Call Chat Button -->
    <CallFeedbackChat v-if="canUseBaoAudio && canUseSingleCallTTYD" :callId="callId" />
    <!---->
    <div v-if="!loadingDone">
      <progress-indicator></progress-indicator>
    </div>
    <div v-else>
      <div class="container-fluid">
        <div class="mb-4">
          <router-link to="/calls" class="btn btn-secondary">
            <i class="fas fa-chevron-left pr-2"></i>
            {{ staticText.backToConversationHistoryLabel }}
          </router-link>
        </div>

        <div class="d-flex justify-content-between align-items-center">
          <h3 id="sm-conversation-summary-title-202101041318" class="title">
            {{ staticText.conversationSummaryLabel }}
          </h3>

          <div class="d-flex">
            <div v-if="canModifyCallsRealTag" class="d-flex mb-2">
              <bao-checkbox
                :value="isCallTagedReal"
                :switchStyle="false"
                :label="staticText.realCallLabel"
                @input="updateCallRealTag($event)"
              />
            </div>
            <div v-if="callDetails && callDetails.created_by && isUserCreator(callDetails.created_by.pk)" class="share-page mb-2 ml-3">
              <b-btn variant="secondary" class="mr-1" @click="openShareModal=true">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" v-bind:class="'svg-icon mr-1 fill-slate-80'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M503.691 189.836L327.687 37.851C312.281 24.546 288 35.347 288 56.015v80.053C127.371 137.907 0 170.1 0 322.326c0 61.441 39.581 122.309 83.333 154.132 13.653 9.931 33.111-2.533 28.077-18.631C66.066 312.814 132.917 274.316 288 272.085V360c0 20.7 24.3 31.453 39.687 18.164l176.004-152c11.071-9.562 11.086-26.753 0-36.328z"/></svg>
                {{ staticText.shareCallLabel }}
              </b-btn>
            </div>
          </div>
        </div>

        <div class="mb-3">
          <div class="summary-components">
            <div class="row">
              <div class="col-md-6 col-lg-4">
                <!-- Counterparts -->
                <b-list-group class="counterparts-block mb-2">
                  <b-list-group-item>
                    <div class="list-group-item__label">{{ staticText.counterpartLabel }}</div>
                    <div
                      id="no-counterpart-selected-202012241523"
                      v-if="!counterpartsAvailable"
                    >
                      {{ staticText.noCounterpartsSelected }}
                    </div>
                    <div v-else>
                      <counterpart-display-cell
                        v-for="(counterpart, index) in summary.counterparts"
                        :key="index"
                        :id="'counterpart-display-202103221659-' + index"
                        :name="counterpart.name"
                        :type="counterpart.type"
                        :crm_service="counterpart.crm_service"
                        :direct_link="counterpart.direct_link"
                        class="single-counterpart"
                      >
                        <template slot="end">
                          <div class="d-flex">
                            <error-modal
                              :id="
                                'counterpart-error-btn-202103221658-' + index
                              "
                              v-if="counterpart.errors"
                              error-header-text=""
                              :title="staticText.errorSavingCRM"
                              :errors="counterpart.errors"
                            ></error-modal>
                          </div>
                        </template>
                      </counterpart-display-cell>
                    </div>
                  </b-list-group-item>
                </b-list-group>
                <div
                  v-if="summary"
                >
                  <!-- User -->
                  <div
                    v-if="summary.created_by"
                    class="input-wrapper"
                  >
                    <input :id="'created_by-'+summary.id"
                           :value="summary.created_by.full_name"
                           disabled
                           class="w-100"
                    >
                    <span class="input-label">
                      {{ staticText.userLabel }}
                    </span>
                  </div>

                  <!-- Playbook -->
                  <div
                    v-if="summary.talkscript"
                    class="input-wrapper mt-2"
                  >
                    <input :id="'playbook-'+summary.id"
                           :value="summary.talkscript.name"
                           disabled
                           class="w-100"
                    >
                    <span class="input-label">
                      {{ staticText.playbookLabel }}
                    </span>
                  </div>

                  <!-- Call Date Time -->
                  <div class="input-wrapper mt-2">
                    <input
                      :id="'conversation-date-time-' + summary.id"
                      :value="summary.date + ' ' + summary.time"
                      disabled
                      class="w-100"
                    />
                    <span class="input-label">
                      {{ staticText.conversationDateTimeLabel }}
                    </span>
                  </div>

                  <!-- Call Duration -->
                  <div
                    v-if="summary.duration && summary.duration > 0"
                    class="input-wrapper mt-2"
                  >
                    <input
                      :id="'duration-' + summary.id"
                      :value="convertMillisecondsToHHMMSS(summary.duration)"
                      disabled
                      class="w-100"
                    />
                    <span class="input-label">
                      {{ staticText.callDurationLabel }}
                    </span>
                  </div>

                  <!-- Call Result -->
                  <div
                    v-if="canUseCallResult && summary.result"
                    class="input-wrapper mt-2"
                  >
                    <input
                      :id="'call-result-' + summary.id"
                      :value="summary.result"
                      disabled
                      class="w-100"
                    />
                    <span class="input-label">
                      {{ staticText.conversationResultLabel }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="border-left col-md-6 col-lg-8">
                <!-- Call Transcript and Text-Match data -->
                <div v-if="!!callTranscriptData" class="px-2 pb-2">
                  <div class="d-flex align-items-center mb-2">
                    <div class="speech-label">
                      bao {{ staticText.SpeechRecognitionLabel }}
                    </div>
                  </div>
                  <real-time-assistant-arc-pie
                    v-if="isValidNumber(callTranscriptData.talking_share)"
                    :label="staticText.talkingShareLabel"
                    label-placement="right"
                    :value="callTranscriptData.talking_share"
                    :size="40"
                    class="mb-3"
                  ></real-time-assistant-arc-pie>
                  <real-time-assistant-arc-pie
                    v-if="isValidNumber(callTranscriptData.talking_speed)"
                    :label="staticText.talkingSpeedLabel"
                    label-placement="right"
                    :value="callTranscriptData.talking_speed"
                    :inner-text="
                      '' + callTranscriptData.talking_speed.toFixed(0)
                    "
                    :total="220"
                    :size="40"
                    class="mb-3"
                  ></real-time-assistant-arc-pie>
                  <real-time-assistant-arc-pie
                    v-if="showTextMatch && isValidNumber(callTranscriptData.text_similarity)"
                    :label="staticText.textSimilarityLabel"
                    label-placement="right"
                    :value="callTranscriptData.text_similarity"
                    :size="40"
                  ></real-time-assistant-arc-pie>
                </div>
              </div>
            </div>
          </div>
        </div>
        <b-tabs
          v-model="activeTabIndex"
          active-nav-item-class="no-border"
          nav-class="no-border d-flex flex-wrap"
          class="call-tabs">
          <b-tab v-if="isVideoAvailable" :title="staticText.VideoTabLabel">
            <bao-video
              :callId="callId"
              class="mb-3"
            ></bao-video>
          </b-tab>
          <b-tab v-if="callAudioAvailable">
            <template slot="title">
              <div class="d-flex align-items-center">
                <div class="mr-1">{{staticText.AudioTabLabel}}</div>
              </div>
            </template>
            <bao-audio
              :call-id="callId"
              class="mb-3"
            ></bao-audio>
          </b-tab>
        </b-tabs>
        <div class="d-none d-md-block">
          <!-- Call Flow Summary table section -->
          <div id="call-flow-summary-202012241532">
            <div class="table-wrapper">
              <div class="table-responsive mt-3 mt-lg-0">
                <!-- displayed only on screen size greater than md -->
                <call-summary-table
                  :call-transcript-data="callTranscriptData"
                  :summary="summary"
                  class="w-100"
                ></call-summary-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <share-call-feedback-modal
      v-if="openShareModal"
      :callId="callId"
      @close="openShareModal=false"
    />
  </div>
</template>

<script>
import { baoDelayService } from "../call"
import axios from "axios"
import CounterpartDisplayCell from "../call/CounterpartDisplayCell"
import ErrorModal from "../base/ErrorModal"
import { mapActions, mapGetters } from "vuex"
import ProgressIndicator from "../base/ProgressIndicator"
import RealTimeAssistantArcPie from "@/apps/call/RealTimeAssistantComponents/RealTimeAssistantArcPie"
import CallSummaryTable from "@/apps/call_history/CallSummaryComponents/CallSummaryTable"
import BaoAudio from "@/apps/call_history/CallFeedbackComponents/BaoAudioAndVideo/BaoAudio.vue"
import BaoVideo from "@/apps/call_history/CallFeedbackComponents/BaoAudioAndVideo/BaoVideo.vue"
import ShareCallFeedbackModal from "@/apps/call_history/CallFeedbackComponents/CallSharing/ShareCallFeedbackModal"
import BaoCheckbox from "@/apps/base/BaoCheckbox.vue"
import { updateCallsRealTagApi } from "@/apps/call_history/api"
import { CALL_TYPE_REAL } from "@/apps/base/constants"
import CallFeedbackChat from "./CallFeedbackComponents/Chat/CallFeedbackChat.vue"

export default {
  name: "CallFeedback",
  components: {
    ProgressIndicator,
    ErrorModal,
    CounterpartDisplayCell,
    RealTimeAssistantArcPie,
    CallSummaryTable,
    BaoAudio,
    BaoVideo,
    ShareCallFeedbackModal,
    BaoCheckbox,
    CallFeedbackChat
  },
  data: function () {
    return {
      axios,
      showPersonalityForm: false,
      selectedCp: null,
      selectedCpIndex: null,
      openShareModal: false,
      isCallTagedReal: false,
      videoDataLoaded: false,
      staticTextDefault: {
        noCounterpartsSelected:
          "No counterpart selected. To select counterpart edit conversation.",
        conversationResultLabel: "Conversation Result",
        conversationDateTimeLabel: "Date & Time",
        userLabel: "User",
        playbookLabel: "Playbook",
        counterpartLabel: "Counterpart",
        errorSavingCRM: "Error while saving to CRM",
        callDurationLabel: "Duration",
        talkingShareLabel: "Talking Share",
        talkingSpeedLabel: "Tempo (WPM)",
        textSimilarityLabel: "Text-Match",
        conversationSummaryLabel: "Call Details",
        backToConversationHistoryLabel: "Back to Conversation History",
        SpeechRecognitionLabel: "Speech Recognition",
        shareCallLabel: "Share",
        realCallLabel: "Real Call",
        VideoTabLabel: "Video",
        AudioTabLabel: "Audio"
      },
      activeTabIndex: 0
    }
  },
  computed: {
    ...mapGetters({
      canUseCallResult: "auth/canUseCallResult",
      canUsePsychologicalContent: "auth/canUsePsychologicalContent",
      canUseSpeechRecognition: "auth/canUseSpeechRecognition",
      showTextMatch: "auth/showTextMatch",
      callTranscriptData: "callAudioStore/getCallTranscriptData",
      loadingSummary: "callSummaryStore/getSummaryLoading",
      summary: "callSummaryStore/getCallSummary",
      canUseVideoIntegration: "auth/canUseVideoIntegration",
      isVideoAvailable: "callVideoStore/isVideoAvailable",
      callDetails: "callSummaryStore/getCallDetails",
      isUserCreator: "callSummaryStore/isUserCreator",
      canModifyCallsRealTag: "auth/canModifyCallsRealTag",
      canUseSingleCallTTYD: "auth/canUseSingleCallTTYD",
      canUseBaoAudio: "auth/canUseBaoAudio"
    }),
    callId () {
      return Number(this.$route.params.id)
    },
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](
        this.$options.name,
        this.staticTextDefault
      )
    },
    counterpartsAvailable () {
      return (
        !!this.summary && !!this.summary.counterparts && !!this.summary.counterparts.length
      )
    },
    loadingDone () {
      return !this.loadingSummary && (this.canUseVideoIntegration ? this.videoDataLoaded : true)
    },
    callAudioAvailable () {
      return this.callDetails && this.callDetails.audio_available
    }
  },
  async mounted () {
    /***
     * check for video integration permission
     ***/
    if (this.canUseVideoIntegration) {
      this.resetCallVideoStore()
      this.checkForMeetingData()
    }
    this.setUp()
  },
  methods: {
    ...baoDelayService.methods,
    ...mapActions({
      deleteSelectedCalls: "calls/deleteSelectedCalls",
      fetchCallTranscriptData: "callAudioStore/fetchCallTranscriptData",
      fetchCallSummary: "callSummaryStore/fetchCallSummary",
      fetchCallMeetingData: "callVideoStore/fetchCallMeetingData",
      resetCallVideoStore: "callVideoStore/resetCallVideoStore",
      fetchCallDetails: "callSummaryStore/fetchCallDetails"
    }),
    async updateCallRealTag (value) {
      const payload = {
        call_ids: [this.callId],
        mark_calls_real: value
      }
      await updateCallsRealTagApi(payload)
      // update the isCallTagedReal value
      this.isCallTagedReal = value
    },
    async checkForMeetingData () {
      await this.fetchCallMeetingData({ callId: this.callId })
      this.videoDataLoaded = true
    },
    async setUp () {
      await this.fetchCallDetails({ callId: this.callId })
      await this.fetchCallSummary({ callId: this.callId })
      if (this.canUseSpeechRecognition) this.fetchCallTranscriptData({ callId: this.callId })
      if (this.summary && this.summary.tags.some(tag => tag.name.includes(CALL_TYPE_REAL))) {
        this.isCallTagedReal = true
      }
    }
  }
}
</script>
<style scoped lang="scss">

.call-tabs > .tab-content > .tab-pane:first-child .call-container{
    // Don't need left top radius for the first tab
    border-radius: 0px 16px 16px 16px !important;
}

.summary-components {
  padding: 24px;
  background: rgba(255, 255, 255, 0.4);
  box-shadow: 6px 8px 20px rgba(179, 173, 159, 0.12);
  border-radius: 20px;
}

.counterparts-block {
  .list-group-item {
    padding: 24px 18px 8px;
    background: #ffffff;
    opacity: 0.8;
    box-shadow: 6px 8px 20px rgba(179, 173, 159, 0.12);
    border: none;
    border-radius: 12px;

    .list-group-item__label {
      position: absolute;
      top: 4px;
      left: 16px;
      font-size: 12px;
      color: rgba(42, 45, 82, 0.4);
    }

    .single-counterpart {
      margin-bottom: 8px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

}

.table-responsive {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.fill-slate-80 {
  fill: $slate80;
}

.svg-icon {
  height: 14px;
  width: 14px;
}

.call-tabs {
  :deep(.nav-link.active) {
    border-radius: 16px 16px 0 0;
    background-color: rgba(255, 255, 255, 0.4);
    color: rgba(0, 0, 0, 0.8);
    margin-bottom: 0px !important;
  }
}

// Ensure container has proper positioning context
.container-fluid {
  position: relative;
}
</style>
